// @ts-strict-ignore
// Copyright (C) 2022 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, shareReplay, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { Client } from '../client/client.model';
import { ClientService } from '../client/client.service';
import { AuthService } from '../core/auth/auth.service';
import { EntityService, HasId } from '../core/entity/entity.service';
import { AlertService } from '../shared/alert.service';
import { User, UserRole } from './user.model';

export type CurrentUser = User & Required<HasId> & { featureFlags: LDFlagSet };

@Injectable({
  providedIn: 'root',
})
export class UserService extends EntityService<User> {
  private readonly loadCurrentUser$ = new BehaviorSubject<void>(null);

  /** The ID of the current authenticated {@link User}. */
  readonly currentUserId$ = this.authService.userProfile$.pipe(
    map(profile => profile.userId),
    distinctUntilChanged(),
    shareReplay(1),
  );

  /** The current authenticated {@link User}. */
  readonly currentUser$ = this.loadCurrentUser$.pipe(
    withLatestFrom(this.currentUserId$, (_, id) => id),
    switchMap(id => this.getOne$(id) as Observable<CurrentUser>),
    shareReplay(1),
  );

  constructor(
    protected authService: AuthService,
    protected clientService: ClientService,
    protected alertService: AlertService,
    protected httpClient: HttpClient,
  ) {
    super(alertService, httpClient);
  }

  getAllByClient$(clientId: number): Observable<User[]> {
    return super.getAll$({ client_id: clientId.toString() });
  }

  getAllByRole$(role: keyof typeof UserRole): Observable<User[]> {
    return super.getAll$({ role });
  }

  // 20230327: Unused method. The current logic is also deprecated. Endpoint has changed.
  // updateClientRoleForUser$(clientId: number, userId: number, newRole: keyof typeof ClientRole): Observable<unknown> {
  //   const url = this.getWebApiEndpoint('update-client-role-for-user');
  //   const body = { user_id: userId, client_id: clientId, new_role: newRole };
  //   const req$ = this.httpClient.patch(url, body);
  //   return this.handleUpdateOrDeleteResponse(req$, {
  //     log: `Update client(${clientId}) role(${newRole}) for ${this.singularIdentifier()}(${userId}) failed:`,
  //     nice: `Failed to update client role for ${this.singularIdentifier()}`,
  //   });
  // }

  updateTermsOfUseAgreement$(): Observable<void> {
    return this.currentUserId$.pipe(
      mergeMap((uid: number) => {
        const url = this.getWebApiEndpoint('terms-of-use', uid);
        const req$ = this.httpClient.put(url, {});
        return this.handleUpdateOrDeleteResponse(req$, {
          log: `Update terms of use agreement for ${this.singularIdentifier()}(${uid}) failed:`,
          nice: `Failed to update terms of use agreement for ${this.singularIdentifier()}`,
        });
      }),
      map(() => null),
      tap(() => this.loadCurrentUser$.next()),
    );
  }

  hasRole$(roles: (keyof typeof UserRole)[], userId?: number): Observable<boolean> {
    const userId$ = userId != null ? of(userId) : this.currentUserId$;
    return userId$.pipe(
      mergeMap((id: number) => this.getOne$(id)),
      take(1),
      // Do they have at least one of the required roles?
      map((user: User) => user.roles?.some(role => roles.some(r => r === role))),
    );
  }

  canAccessEducation$(): Observable<boolean> {
    return this.currentUserId$.pipe(
      mergeMap((id: number) =>
        this.clientService.getAllByUser$(id).pipe(
          // User can access education if any of the clients have enabled education.
          map((clients: Client[]) => clients.some(client => client.active && client.enabledEducation)),
        ),
      ),
    );
  }

  changeUserRole(user: Partial<User>, role: keyof typeof UserRole, selected: boolean): void {
    if (!Array.isArray(user.roles)) {
      user.roles = [];
    }

    // Add the role if selected.
    if (selected && !user.roles.includes(role)) {
      user.roles.push(role);
    }

    // Remove the role if not selected.
    if (!selected) {
      user.roles = user.roles.filter(r => r !== role);
    }
  }

  pluralIdentifier(): string {
    return 'users';
  }

  singularIdentifier(): string {
    return 'user';
  }
}
